import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  img: {
    width: '60%',
    height: 'auto',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <img
      className={classes.img}
      src="https://assets-us-01.kc-usercontent.com:443/f570a56f-203b-003b-4ea0-b0bb2fc46aad/0a48a5c8-70ff-438f-ba17-2ce63b14df74/logo-white.svg"
      alt="eMed Logo" />
  );
};

export default LogoIcon;
